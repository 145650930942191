<template>
  <div class="main">
    <header-t />
    <nav2 />
    <div v-if="isRegion == 0" class="location">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>精品课程</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-if="isRegion == 1" class="location">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>地州课程</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-if="isRegion == 2" class="location">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>最新课程</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="cont">
      <div class="type-wrap">
        <!-- 地州 -->
        <dl v-if="isRegion === '1'" class="cation-list">
          <dd>
            <span class="all" :class="{ on: regionTag }" @click="changeRegionAll(true)">全部</span>
            <span :class="{ 'default on': region.regionId == regionCurrent, 'default': region.regionId != regionCurrent }"
              v-for="(region, index) in regionLists" :key="region.regionId" @click="changeRegion(index, region.regionId)"
              :regionId="region.regionId">
              {{ region.regionName }}
            </span>
          </dd>
        </dl>
        <!-- 一级 -->
        <dl class="cation-list" v-if="isRegion != 1">
          <dd>
            <span class="all" :class="{ on: parentTag }" @click="changeParentAll(true)">全部</span>
            <span :class="{ 'default on': one.id == parentCurrent, 'default': one.id != parentCurrent }"
              v-for="(one, index) in classLists" :key="one.id" @click="changeParent(index, one.id)" :id="one.id">
              {{ one.categoryName }}
            </span>
          </dd>
        </dl>
      </div>
      <!-- 地州2级 -->
      <div v-if="regionLists2.length > 0 && isRegion === '1'" class="type-wrap">
        <dl class="cation-list">
          <dd>
            <span class="all" :class="{ on: regionTag2 }" @click="changeRegionAll2(true)">全部</span>
            <span :class="{ 'default on': region.regionId == regionCurrent2, 'default': region.regionId != regionCurrent2 }"
              v-for="(region, index) in regionLists2" :key="region.regionId" @click="changeRegion2(index, region.regionId)"
              :regionId="region.regionId">
              {{ region.regionName }}
            </span>
          </dd>
        </dl>
      </div>
      <div class="result">
        <ul v-if="result.length > 0">
          <li @click="goDetail(item.id)" v-for="item in result" :key="item.id">
            <img class="img" :src="item.logo === '' ? defaultImg : item.logo">
            <div class="infobox">
              <p class="tit">{{ item.courseName }}</p>
              <div class="info">
                <p v-if="item.endSigningTime">截止报名日期：{{ item.endSigningTime }}</p>
              </div>
              <div class="btn_wrap">
                <div class="price">
                  <p v-if="item.price"><span style="font-size: 14px;">¥ </span>{{ item.price }}</p>
                  <p v-else>免费</p>
                </div>
                <el-button round class="btn" @click="goDetail(item.id)">课程详情</el-button>
              </div>
            </div>
          </li>
        </ul>
        <div v-else class="noDataTip">没有查到相关的课程！</div>
        <el-row class="pagination">
          <Pagination :total="search.total" :page.sync="search.page" :limit.sync="search.size" @pagination="init" />
        </el-row>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderT from '@/components/HeaderT'
import Nav2 from '@/components/Nav2'
import Footer from '@/components/Footer'
import { getClass, getRegion, getHighQuality, getCourseList, getNewlyCourseList, getRegionSecond } from '@/api/product.js'
import Pagination from '@/components/Pagination'
export default {
  name: 'HighQuality',
  components: {
    HeaderT,
    Nav2,
    Footer,
    Pagination
  },
  data () {
    return {
      defaultImg: 'https://qiniu-cdn6.jinxidao.com/uploads/active/images/loading-238x184.png',
      regionTag: true, // 地州父类
      regionTag2: true, // 地州2级
      parentTag: true, // 一级父类
      // 搜索条件
      search: {
        page: 1,
        size: 40,
        total: 1

      },
      regionCurrent: 0,
      parentCurrent: 0,
      regionCurrent2: 0, //地州2级选中的id
      classLists: [],//一级分类数据
      regionLists: [],//地州数据
      categoryId: 0, // 默认为0 获取全部数据
      regionId: 0, // 默认为0 获取全部数据
      isRegion: null, // 是否为地州标记
      result: [],//列表搜索结果
      regionLists2: [],//地州2级数据
    }
  },
  mounted () {
    // this.isRegion = this.$route.query.isRegion == 1 ? true : false
    this.isRegion = this.$route.query.isRegion;
    console.log(this.isRegion, 'dsfsdfsdf');
    this.getClassData()
    this.init()
  },
  methods: {
    async init () {
      if (this.isRegion === '1') { // 地州先查 地州标签
        await this.getRegionData()
        await this.getRegionCourse()
      } else if (this.isRegion === '0') { // 精品课程
        this.getRegionCourse() //直接查课程
      } else if (this.isRegion === '2') { // 精品课程
        this.getRegionCourse() //直接查课程
      }

    },
    //2级数据
    getRegionSecondData (id) {
      return new Promise((resolve, reject) => {
        getRegionSecond(id).then(res => {
          if (res.data.code === 0) {
            this.regionLists2 = res.data.data.rows
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    //获取地州标签接口数据
    getRegionData () {
      // console.log('getClassData...');
      return new Promise((resolve, reject) => {
        getRegion().then(res => {
          if (res.data.code === 0) {
            this.regionLists = res.data.data.rows
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    //获取一级分类数据
    getClassData () {
      getClass().then(res => {
        if (res.data.code === 0) {
          this.classLists = res.data.data.rows
          console.log('classLists:', this.classLists);
          // resolve()
        } else {
          // reject()
        }
      })
    },
    // 查课程
    getRegionCourse () {
      console.log('最新课程....' + this.isRegion);
      if (this.isRegion === '1') {
        // getRegionCourse(Object.assign(this.search,{categoryId: this.categoryId},{regionId: this.regionId})).then(res=>{
        //   if(res.data.code === 0) {
        //     console.log('地州.....');
        //     this.search.total = res.data.data.total
        //     this.result = res.data.data.rows
        //   }
        // })
        getCourseList(Object.assign(this.search, { categoryId: this.regionId })).then(res => {
          if (res.data.code === 0) {
            console.log('地州.....');
            this.search.total = res.data.data.total
            this.result = res.data.data.rows
          }
        })
      } else if (this.isRegion === '0') { // 精品课程
        getHighQuality(Object.assign(this.search, { categoryId: this.categoryId })).then(res => {
          if (res.data.code === 0) {
            console.log('精品课程....');
            this.result = res.data.data.rows
            this.search.total = res.data.data.total
          }
        })
      } else if (this.isRegion === '2') { // 最新课程
        getNewlyCourseList(Object.assign(this.search, { categoryId: this.categoryId })).then(res => {
          if (res.data.code === 0) {
            this.result = res.data.data.rows
            this.search.total = res.data.data.total
          }
        })
      }

    },
    //地州分类  all  点击
    changeRegionAll (val) {
      this.regionTag = val
      this.regionCurrent = 0
      this.regionId = 229 // 获取全部数据

      this.getRegionCourse()
    },
    //地州分类2级  all  点击
    changeRegionAll2 (val) {
      this.regionTag = val
      this.regionTag = false
      this.regionTag2 = true;
      this.regionCurrent2 = 0
      this.regionId =  this.regionCurrent // 获取全部数据
      this.getRegionCourse()
    },
    // 一级分类all 点击
    changeParentAll (val) {
      this.parentTag = val
      this.parentCurrent = 0
      this.categoryId = 0
      this.getRegionCourse()
    },
    // 地州分类点击
    changeRegion (index, id) {
      this.regionTag = false
      this.regionTag2 = true;
      this.regionId = id
      this.regionCurrent = id
      this.getRegionSecondData(id)
      this.getRegionCourse()
    },
    //地级2级分类
    changeRegion2 (index, id) {
      this.regionTag = false;
      this.regionTag2 = false;
      this.regionId = id
      this.regionCurrent2 = id
      this.getRegionCourse()
    },
    // 一级分类点击
    changeParent (index, id) {
      this.parentTag = false
      this.parentCurrent = id
      this.categoryId = id
      this.getRegionCourse()
    },
    // 跳转详细页面
    goDetail (id) {
      this.$router.push({
        path: '/classdetail',
        query: {
          id,
        }
      })
    },

  }
}
</script>

<style  rel="stylesheet/scss" lang="scss" scoped>
.main {
  height: 100%;
  background: url('../../assets/play-bg.jpeg') left top repeat-y #fff !important;

  .location {
    margin: 20px auto 0;
    // border:1px #f00 solid;
    width: 1170px;
    text-align: left;
  }

  .cont {
    margin: 16px auto 20px;
    width: 1170px;

    .type-wrap {
      background-color: #fff;
      padding: 10px;

      .cation-list {
        overflow: hidden;
        border-bottom: 1px dashed #d2d2d2;

        dt {
          float: left;
          width: 64px;
          font-weight: 700;
          line-height: 48px;
          position: relative;
          color: #333
        }

        dt:after {
          content: "";
          position: absolute;
          right: 12px;
          top: 22px;
          border: 1px solid #333;
          border-width: 0 1px 1px 0;
          width: 4px;
          height: 4px;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          transform: rotate(-45deg)
        }

        dd {
          overflow: hidden;

          span {
            color: #8d8d91;
            font-size: 14px;
            height: 24px;
            float: left;
            padding: 0 10px;
            line-height: 24px;
            border-radius: 4px;
            margin: 7px;
            background: transparent
          }

          .on,
          :hover {
            color: #fff;
            background-color: #0065b3;
            cursor: pointer;

          }

          :last-child {
            border-right: 0 none
          }
        }
      }

      :last-of-type dd {
        border-bottom: 0 none
      }
    }

    .result {
      margin-top: 20px;

      // background-color: #f5f5f5;
      ul {
        display: flex;
        flex-wrap: wrap;

        /* justify-content: space-between; */
        li {
          width: 270px;
          background-color: #fff;
          padding: 0px;
          margin-bottom: 20px;
          box-sizing: border-box;
          margin-right: 30px;
          border-radius: 4px;
          transition: all 0.2s ease-in;
          position: relative;
          top: 0;
          border: 1px solid #eee;

          .img {
            width: 100%;
            height: 178px;
            overflow: hidden;

          }

          .infobox {
            padding: 5px 0 10px;
          }

          .tit {
            font-size: 16px;
            color: #333;
            text-align: left;
            margin-top: 4px;
            padding: 0 10px;
            font-weight: bold;
          }

          .info {
            color: #666;
            text-align: left;
            line-height: 23px;
            height: 23px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 14px;
            margin: 5px 0;
            padding: 0 10px;
          }

          .btn_wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;

            .price {
              width: 50%;
              text-align: left;
              // text-indent: 20px;
              font-size: 20px;
              font-weight: bold;
              color: #626263;
            }

            .btn {
              border-radius: 30px;
              padding: 6px 12px;
              font-size: 12px;

            }
          }

          .w100 {
            width: 100%;
          }
        }

        li:nth-of-type(4n+4) {
          margin: 0 0px 20px 0;
        }

        li:hover {
          top: -5px;
          box-shadow: 0px 10px 9px 0 #ddd;
          cursor: pointer;

          .btn {
            background: #084590;
            color: #fff;
          }
        }
      }

      .pagination {
        margin-top: 30px;
        text-align: center;

        .pagination-container {
          // background-color: #f5f5f5;
        }
      }

      .noDataTip {
        color: #666
      }
    }
  }
}</style>